import { Box, Typography } from "@mui/material";

export default function AboutSectionContent() {
  return (
    <Box
      id="prout"
      sx={{
        textAlign: "justify",
        minHeight: "inherit", // Avoid having to set padding as the Wrapper is not centering it children (because we don't want the welcome section to be centered vertically yet (not enough content))
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <Typography variant="h3">👋</Typography>
      <Typography variant="body1">
        Welcome to MyMoneyStash, a platform dedicated to empowering individuals
        on their journey towards financial independence. Our mission is to
        provide you with powerful tools and resources that simplify complex
        personal finance calculations, helping you make informed decisions that
        align with the Financial Independence, Retire Early (FIRE) philosophy.
      </Typography>
      <Typography variant="h3">🔥</Typography>
      <Typography variant="body1">
        Whether you're aiming to optimize your savings rate, plan for early
        retirement, or efficiently manage your investments, our user-friendly
        calculators are designed to assist you in achieving your financial
        goals.
      </Typography>
      <Typography variant="h3">🌱</Typography>

      <Typography variant="body1">
        Join us in embracing frugality, healthy investing, and the pursuit of
        financial freedom, as we guide you towards a future where your
        hard-earned money works for you, granting you the freedom to live life
        on your terms.
      </Typography>
      <Typography variant="h3">🤝</Typography>
      {/* and potentially harmonizing with nature, minimizing
          excess, and maximizing the joy found in life's essential experiences. */}
    </Box>
  );
}
