import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import pkg from "../package.json";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { hydrate, render } from "react-dom";


Amplify.configure({
  Auth: {
    userPoolId: "eu-west-2_JolOmYhCR",
    userPoolWebClientId: "6naibsqii5lcnplj741d7un8lj",
  },
});

const location = window.location.href;
const staging = location.startsWith("https://staging.mymoneystash.com/");
const local = location.startsWith("http://localhost:3000/");
const hidePageBehindAuth = staging || local;
console.log(`Version: ${pkg.version}`);
console.log(`Environment: ${process.env.REACT_APP_ENV}`);

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TAG) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG);
} else {
  console.error("GOOGLE_ANALYTICS_TAG is not set");
}

// Encapsulate components into one component
const MainApp = ({ hidePageBehindAuth }: { hidePageBehindAuth: boolean }) => (
  <React.StrictMode>
    {hidePageBehindAuth ? (
      <Authenticator hideSignUp loginMechanisms={["email"]}>
        {({ signOut, user }) => (
          <BrowserRouter>
            <App />
          </BrowserRouter>
        )}
      </Authenticator>
    ) : (
      <BrowserRouter>
        <App />
      </BrowserRouter>
    )}
  </React.StrictMode>
);

ReactDOM.render(
  <MainApp hidePageBehindAuth={hidePageBehindAuth} />,
  document.getElementById("root")
);
const rootElement = document.getElementById("root") as HTMLElement;

if (rootElement.hasChildNodes()) {
  hydrate(<MainApp hidePageBehindAuth={hidePageBehindAuth} />, rootElement);
} else {
  render(<MainApp hidePageBehindAuth={hidePageBehindAuth} />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
