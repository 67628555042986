import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { customPalette } from "../../App";

export default function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2500);
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: customPalette.background.blue,
        height: "60vh", // Don't change me, the footer will fill the gap at the bottom

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h3">Page Not Found</Typography>
      <Typography variant="body1"> Redirecting to home page...</Typography>
    </Box>
  );
}
