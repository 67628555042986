import { Box, Typography } from "@mui/material";
import { SignupForm } from "../../../base";
export default function SignupSectionContent() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "16px",
        padding: "20px 0",
      }}
    >
      <Box>
        <Typography variant="h1" fontSize="1.75rem">
          Get the latest from My Money Stash.
        </Typography>
        <Typography variant="body1">
          Sign up to receive new feature updates.
        </Typography>
      </Box>
      <SignupForm />
    </Box>
  );
}
