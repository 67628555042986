import { Box } from "@mui/material";

const ChartWrapper = (props: any) => {

  return (
    <Box
      id="chart_wrapper"
      sx={{
        height: "50vh",
        width: "97vw",
        display: "flex",
        justifyContent: "center", // Needed to center the ResponsiveContainer horizontally
      }}
    >
      {props.children}
    </Box>
  );
};

export default ChartWrapper;
