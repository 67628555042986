type Local = "en-GB";
type Currency = "GBP";

// const numberToCurrency = (
//   num: number,
//   options?: Partial<{
//     compact: boolean;
//     local: Local;
//     currency: Currency;
//   }>
// ) => {
//   const defaultOptions = {
//     compact: false,
//     local: "en-GB" as Local,
//     currency: "GBP",
//   };
//   const mergedOptions = { ...defaultOptions, ...options };
//   return new Intl.NumberFormat(mergedOptions.local, {
//     style: "currency",
//     currency: mergedOptions.currency,
//     maximumSignificantDigits: 3,
//     notation: mergedOptions.compact ? "compact" : "standard",
//   }).format(num);
// };

const numberToCurrency = (
  num: number,
  {
    compact = false,
    local = "en-GB",
    currency = "GBP",
  }: {
    compact?: boolean;
    local?: Local;
    currency?: Currency;
  } = {}
) => {
  return new Intl.NumberFormat(local, {
    style: "currency",
    currency,
    maximumSignificantDigits: 3,
    notation: compact ? "compact" : "standard",
  }).format(num);
};

export default numberToCurrency;
