import React, { useState } from "react";
import {
  TextField,
  InputLabel,
  Typography,
  InputAdornment,
  Box,
} from "@mui/material";
interface ValidationRule {
  function: (a: string | any) => boolean;
  errorMessage: string;
}

export interface FormInputGroupProps {
  tfId: string;
  tfLabel: string;
  tfDescription?: string;
  tfPlaceholder?: string;
  tfInputAdornment?: string;
  tfInputAdornmentPosition?: "end" | "start";
  tfInputType?: "text" | "number";
  tfInputNumberStep?: number;
  tfInputNumberMax?: number;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  validationRules: ValidationRule[];
  isInputValid: boolean;
  setIsInputValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormInputGroup = (props: FormInputGroupProps) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setIsInputValid(true); // For UX (remove red when user is trying again)

    props.setInputValue(event.target.value);
  };

  // TODO - move validateFormInput function into its own file and add unit test
  const validateInput = (
    input: string,
    validationRules: ValidationRule[],
    setInputValidState: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessageState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    let hasError: boolean;
    validationRules.forEach((validationRule) => {
      if (hasError) return; // exit after first validation that failed

      const isValid = validationRule.function(input);
      if (!isValid) {
        hasError = true;
        setErrorMessageState(validationRule.errorMessage);
        setInputValidState(isValid);
      } else {
        hasError = false;
      }
    });
  };
  // Necessary to make the input adornment position configurable
  const inputProps =
    props.tfInputAdornmentPosition === "end"
      ? {
          sx: {
            borderRadius: "8px",
          },
          endAdornment: (
            <InputAdornment position="end">
              {props.tfInputAdornment || "£"}
            </InputAdornment>
          ),
        }
      : {
          sx: {
            borderRadius: "8px",
          },
          startAdornment: (
            <InputAdornment position="start">
              {props.tfInputAdornment || "£"}
            </InputAdornment>
          ), // TODO - Make currency customizable for user.
        };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <InputLabel htmlFor={props.tfId}>{props.tfLabel}</InputLabel>
      {props.tfDescription && (
        <Typography variant="body2">{props.tfDescription}</Typography>
      )}
      <TextField
        // label={props.tfLabel} // Ugly but makes tests work and possibly better UI and accessibility
        size="medium"
        sx={{
          backgroundColor: "grey.100",
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              // "&.Mui-focused fieldset" hides the value inside the input behind the backgroundColor
              backgroundColor: "common.white",
            },
          },
        }}
        id={props.tfId}
        variant="outlined"
        value={props.inputValue}
        type={props.tfInputType || "text"}
        onChange={handleInputChange}
        onBlur={(event) =>
          validateInput(
            event.target.value,
            props.validationRules,
            props.setIsInputValid,
            setErrorMessage
          )
        }
        error={!props.isInputValid}
        helperText={!props.isInputValid && errorMessage}
        InputProps={inputProps}
        inputProps={{
          step: props.tfInputNumberStep && props.tfInputNumberStep.toString(),
          max: props.tfInputNumberMax && props.tfInputNumberMax.toString(),
        }}
      />
    </Box>
  );
};

export default FormInputGroup;
