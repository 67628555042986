import { Box, Typography } from "@mui/material";

import { customPalette } from "../../App";

const CustomLegend = ({
  typo1,
  typo2,
  typo3,
  typo4,
}: {
  typo1: string;
  typo2: string;
  typo3: string;
  typo4: string;
}) => {
  return (
    <Box
      sx={{
        // Position of the CustomLegend
        position: "absolute",
        left: "20%",
        top: "6%",
        zIndex: 1,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        backgroundColor: customPalette.background.white,
        padding: "1rem 1.5rem",
        border: `2px solid ${customPalette.text.darkgrey}`,
        borderRadius: "8px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      }}
    >
      <Typography variant="body1" sx={{ color: customPalette.text.primary }}>
        {typo1}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontWeight: "bold",
          color: customPalette.background.blue,
          marginBottom: "5px",
        }}
      >
        {typo2}
      </Typography>
      <Typography variant="body2" sx={{ color: customPalette.text.primary }}>
        {typo3}
      </Typography>
      <Typography variant="body1" sx={{ color: customPalette.text.darkgrey }}>
        {typo4}
      </Typography>
    </Box>
  );
};

export default CustomLegend;
