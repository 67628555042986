import {
  Bar,
  BarChart,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Box } from "@mui/material";
import { customPalette } from "../../../../../App";
import { numberToCurrency } from "../../../../../helpers/formatters";

export interface MortgageBarChartData {
  loanAmount: number;
  date: number;
}

interface MortgageReductionChartProps {
  data: number[];
  xAxisDataKey?: "date";
  yAxisDataKey?: "loanAmount";
}
const mortgageReductionChartDataFormatter = (
  data: number[]
): MortgageBarChartData[] =>
  data.map((value, index) => ({
    loanAmount: value,
    date: new Date(2021 + index, 0, 1).getFullYear(),
  }));

const MortgageReductionChart = ({
  data,
  xAxisDataKey = "date",
  yAxisDataKey = "loanAmount",
}: MortgageReductionChartProps) => {
  const mortgageReductionChartData = mortgageReductionChartDataFormatter(data);
  return (
    <>
      {/* HACK - investigate but currently it looks like the ResponsiveContainer or BarChart needs to be wrapped
        in a box (or container with explicit height and width, minHeight or minWidth make the responsive container disappear) */}
      <ResponsiveContainer width="97%">
        <BarChart data={mortgageReductionChartData}>
          <XAxis
            dataKey={xAxisDataKey}
            stroke={customPalette.background.dark}
            padding={{ left: 18 }}
            tickMargin={10}
            // TODO - tickFormatter={dateFormatter} -- the formatting is done directly in the data maybe its better that calculatedAccruedAmount returns a DateTime object and the consumers does the formatting?
          />
          <YAxis
            stroke={customPalette.background.dark}
            tickFormatter={(value) =>
              numberToCurrency(value, { compact: true })
            }
            type="number"
            mirror={true}
            tickMargin={3}
            tickCount={6} // How many ticks / labels to show, we can make this dynamic based on the loanAmount if loanAmount >1M show 10 or else
            axisLine={true}
            orientation="right"
          />
          {/* <Tooltip
            formatter={(value) => {
              return formatCurrency(value);
            }}
          />
          <Legend
            formatter={(value) => {
              return formatCurrency(value);
            }}
          /> */}
          <Bar dataKey={yAxisDataKey} fill={customPalette.background.blue} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default MortgageReductionChart;
