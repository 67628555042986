// Approach decided: do not round any numbers, let the consumer of the function decide how to round the numbers.

function calculateMortgageMonthlyRepayment(
  initialLoanAmount: number,
  loanDurationYears: number,
  yearlyInterestRate: number
) {
  const monthlyInterestRate = yearlyInterestRate / (100 * 12);
  const loanDurationMonths = loanDurationYears * 12;
  return (
    (initialLoanAmount *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, loanDurationMonths)) /
    (Math.pow(1 + monthlyInterestRate, loanDurationMonths) - 1)
  );
}

function calculateMortgageDuration(
  loanAmount: number,
  monthlyPayment: number,
  yearlyInterestRate: number
) {
  const monthlyInterestRate = yearlyInterestRate / (100 * 12);
  let loanDurationMonths = 0;
  let remainingBalance = loanAmount;

  while (remainingBalance > 0) {
    const interestAmount = remainingBalance * monthlyInterestRate;
    const principalAmount = monthlyPayment - interestAmount;
    remainingBalance -= principalAmount;
    loanDurationMonths++;
  }

  const loanDurationYears = Math.floor(loanDurationMonths / 12);
  return loanDurationYears;
}

function calculateMortgageDurationOptimized(
  loanAmount: number,
  monthlyPayment: number,
  yearlyInterestRate: number
) {
  const monthlyInterestRate = yearlyInterestRate / 100 / 12;

  if (monthlyPayment <= 0) {
    return Infinity; // Indicate infinite loan duration for zero or negative monthly payment
  }

  const numerator =
    Math.log(monthlyPayment) -
    Math.log(monthlyPayment - loanAmount * monthlyInterestRate);
  console.log({ numerator });
  const denominator = Math.log(1 + monthlyInterestRate);

  const loanDurationMonths = Math.ceil(numerator / denominator);

  const loanDurationYears = Math.floor(loanDurationMonths / 12);
  return loanDurationYears;
}
const calculateMonthlyMortgageReductionOverTime = (
  loanAmount: number,
  monthlyPayment: number,
  yearlyInterestRate: number
) => {
  const monthlyInterestRate = yearlyInterestRate / (100 * 12);

  let mortgageReduction: number[] = [loanAmount];
  let remainingBalance = loanAmount;
  let currentMonthInterests;
  while (remainingBalance > monthlyPayment) {
    currentMonthInterests = remainingBalance * monthlyInterestRate;
    remainingBalance =
      remainingBalance - (monthlyPayment - currentMonthInterests);
    mortgageReduction.push(
      Math.ceil(remainingBalance) // converts floats to integers to save memory in the browser
    );
  }

  return mortgageReduction;
};

const calculateYearlyMortgageReductionOverTime = (
  monthlyReduction: number[]
) => {
  return monthlyReduction.filter((_el, idx) => idx % 12 === 0);
};

// function calculateMonthlyInterestCostsOverLoanTerm(
//   initialLoanAmount: number,
//   loanDurationYears: number,
//   yearlyInterestRate: number
// ) {
//   const monthlyPayment = calculateMortgageMonthlyRepayment(
//     initialLoanAmount,
//     loanDurationYears,
//     yearlyInterestRate
//   );

//   const monthlyInterestRate = yearlyInterestRate / (100 * 12);
//   const loanDurationInMonths = loanDurationYears * 12;
//   let monthlyInterests: number[] = [];

//   let loanAmountReduction: number[] = [];

//   for (let i = 1; i <= loanDurationInMonths; i++) {
//     if (i === 1) {
//       const currentMonthInterests = initialLoanAmount * monthlyInterestRate;
//       monthlyInterests.push(initialLoanAmount * monthlyInterestRate);
//       loanAmountReduction.push(
//         initialLoanAmount - (monthlyPayment - currentMonthInterests)
//       );
//     } else {
//       const totalInterestPaidSoFar = monthlyInterests
//         .slice(0, i)
//         .reduce((a, b) => a + b, 0);

//       const equity = monthlyPayment * (i - 1) - totalInterestPaidSoFar; // What you have paid off so far
//       const currentMonthInterest =
//         (initialLoanAmount - equity) * monthlyInterestRate;
//       monthlyInterests.push(currentMonthInterest);
//       loanAmountReduction.push(initialLoanAmount - equity);
//     }
//   }
//   return monthlyInterests;
// }

// const calculateMonthlyPrincipalAndInterestPaymentBreakdown = (
//   initialLoanAmount: number,
//   loanDurationYears: number,
//   yearlyInterestRate: number
// ) => {
//   const monthlyInterests = calculateMonthlyInterestCostsOverLoanTerm(
//     initialLoanAmount,
//     loanDurationYears,
//     yearlyInterestRate
//   );
//   const monthlyPayment = calculateMortgageMonthlyRepayment(
//     initialLoanAmount,
//     loanDurationYears,
//     yearlyInterestRate
//   );
//   return monthlyInterests.map((interest, m) => ({
//     month: m,
//     interest: Number(interest.toFixed(2)),
//     principal: Number((monthlyPayment - interest).toFixed(2)), // equity repaid for that month
//   }));
// };

export {
  calculateMortgageMonthlyRepayment,
  calculateMonthlyMortgageReductionOverTime,
  calculateYearlyMortgageReductionOverTime,
  calculateMortgageDuration,
  calculateMortgageDurationOptimized,
  // calculateMonthlyInterestCostsOverLoanTerm,
  // calculateMonthlyPrincipalAndInterestPaymentBreakdown,
};
