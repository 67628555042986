import { useState, useMemo } from "react";
import {
  calculateMonthlyMortgageReductionOverTime,
  calculateYearlyMortgageReductionOverTime,
  calculateMortgageMonthlyRepayment,
} from "../../../../../helpers/finance/mortgage";
import { Box, Slider, debounce } from "@mui/material";
import { GenericForm, CustomLegend, ChartWrapper } from "../../../../base";
import {
  required,
  isNumericValue,
  isGreaterThanOrEqualTo,
  isLessThanOrEqualTo,
} from "../../../../../helpers/validation/rules";

import MortgageReductionChart from "./MortgageReductionChart";
import {
  numberToCurrency,
  monthNumberToYMString,
} from "../../../../../helpers/formatters";

// How soon could you reimburse your Mortgage?
/*
- How much have you got left to reimburse? 
- How much could you repay each month? 
- What interest rate are you hoping to get? 

*/

export default function MortgageReduction() {
  const initialState = {
    loanAmount: "200000",
    loanTerm: "20",
    yearlyInterestRate: "2.5",
    isLoanAmountInputValid: true,
    isLoanTermInputValid: true,
    isYearlyInterestRateInputValid: true,
    showResult: false,
  };

  const initialCalculatedState = {
    monthlyRepayment: useMemo(
      () =>
        calculateMortgageMonthlyRepayment(
          Number(initialState.loanAmount),
          Number(initialState.loanTerm),
          Number(initialState.yearlyInterestRate)
        ),
      [
        initialState.loanAmount,
        initialState.loanTerm,
        initialState.yearlyInterestRate,
      ]
    ),
  };

  // Form Input State
  const [loanAmount, setLoanAmount] = useState(initialState.loanAmount);
  const [loanTerm, setLoanTerm] = useState(initialState.loanTerm);
  const [yearlyInterestRate, setYearlyInterestRate] = useState(
    initialState.yearlyInterestRate
  );

  // Form Input Validation State
  const [isLoanAmountInputValid, setIsLoanAmountInputValid] = useState(true);
  const [isLoanTermInputValid, setIsLoanTermInputValid] = useState(true);
  const [isYearlyInterestRateInputValid, setIsYearlyInterestRateInputValid] =
    useState(true);

  // ⚠️ We use useState here as the onChange sets the other input state variable values (better for UX with regards to validation)
  // As we only want to trigger the calculation of payment breakdown once the form is submitted and if all inputs are valid (as the calculations is quite expensive it can freeze the browser)
  // We validate each field individually onBlur only so we don't want to calculate for every change
  const [monthlyRepayment, setMonthlyRepayment] = useState(
    initialCalculatedState.monthlyRepayment
  );
  const initialMMR = useMemo(
    () =>
      calculateMonthlyMortgageReductionOverTime(
        Number(initialState.loanAmount),
        initialCalculatedState.monthlyRepayment,
        Number(initialState.yearlyInterestRate)
      ),
    [
      initialState.loanAmount,
      initialCalculatedState.monthlyRepayment,
      initialState.yearlyInterestRate,
    ]
  );
  const [monthlyMortgageReduction, setMonthlyMortgageReduction] =
    useState(initialMMR);

  // State-derived variable
  const isFormValid =
    isLoanAmountInputValid &&
    isLoanTermInputValid &&
    isYearlyInterestRateInputValid;

  const handleFormSubmit = () => {
    if (!isFormValid) {
      // By pass calculations if form is not valid
      // we could also setFormErrorMessage("Please fill in all the required fields"); appropriately to a given restriction we want to impose at the form level (taking into account value from all fields rather than individual field validation)
      return;
    }
    // Calculate monthly repayment
    const mRepayment = calculateMortgageMonthlyRepayment(
      Number(loanAmount),
      Number(loanTerm),
      Number(yearlyInterestRate)
    );
    const mmr = calculateMonthlyMortgageReductionOverTime(
      Number(loanAmount),
      mRepayment,
      Number(yearlyInterestRate)
    );
    setMonthlyRepayment(mRepayment);
    setMonthlyMortgageReduction(mmr);
  };
  const resetForm = () => {
    // Reset the form values (state)
    setLoanAmount(initialState.loanAmount);
    setLoanTerm(initialState.loanTerm);
    setYearlyInterestRate(initialState.yearlyInterestRate);
    // Reset the form validation state
    setIsLoanAmountInputValid(initialState.isLoanAmountInputValid);
    setIsLoanTermInputValid(initialState.isLoanTermInputValid);
    setIsYearlyInterestRateInputValid(
      initialState.isYearlyInterestRateInputValid
    );
    // Reset the MortgageReductionChart and Legend
    setMonthlyMortgageReduction(initialMMR);
    setMonthlyRepayment(initialCalculatedState.monthlyRepayment);
  };

  // const debounceSliderChange = debounce((value: number | number[]) => {
  //   // setYearlyMortgageReduction was implemented as a state setter function, so we can control more precisely when the calculation is triggered
  //   // Only when the calculate button is clicked or when the slider is changed as mortgage reduction calculation can be quite expensive and freeze the browser
  //   // As the slider change can be quite frequent we use debouncing to avoid triggering the calculation too often.
  //   console.log(
  //     "debounceSliderChange triggered with:",
  //     `loanAmount: ${loanAmount}, loanTerm: ${value}, yearlyInterestRate: ${yearlyInterestRate}`
  //   );
  //   if (!isFormValid) {
  //     // Hide graph in the UI
  //     return;
  //   }

  //   const mmr = calculateMonthlyMortgageReductionOverTime(
  //     Number(loanAmount),
  //     value as number,
  //     Number(yearlyInterestRate)
  //   );
  //   setMonthlyMortgageReduction(mmr);
  // }, 500);
  // const handleSliderValueChange = useCallback(
  //   (_: Event, value: number | number[]) => {
  //     console.log("handleSliderValueChange triggered with:", value);
  //     // We are still updating some UI elements upon every slider change event to avoid the impression of a frozen UI
  //     setLoanTerm(value.toString());
  //     debounceSliderChange(value);
  //     return;
  //   },
  //   []
  // );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "32px",
      }}
    >
      <GenericForm
        formInputGroups={[
          {
            tfId: "outlined-loan-amount",
            tfLabel: "Loan Amount",
            tfDescription: "How much have you got left to reimburse?",
            tfInputType: "number",
            inputValue: loanAmount,
            setInputValue: setLoanAmount,
            validationRules: [
              {
                function: required,
                errorMessage: "Please enter a value.",
              },
              {
                function: isNumericValue,
                errorMessage: "Please enter a numeric value. 🔢",
              },
              {
                function: isGreaterThanOrEqualTo(2000),
                errorMessage: "Minimum loan amount is £2,000.",
              },
            ],
            isInputValid: isLoanAmountInputValid,
            setIsInputValid: setIsLoanAmountInputValid,
          },
          {
            tfId: "outlined-monthly-repayment",
            tfLabel: "Mortgage Duration",
            tfDescription:
              "How soon would you like your mortgage to be paid off?",
            tfInputType: "number",
            tfInputAdornment: "years",
            tfInputAdornmentPosition: "end",
            // tfInputNumberStep: 1,
            inputValue: loanTerm,
            setInputValue: setLoanTerm,
            validationRules: [
              {
                function: required,
                errorMessage: "Please enter a value.",
              },
              {
                function: isNumericValue,
                errorMessage: "Please enter a numeric value. 🔢",
              },
              {
                function: isGreaterThanOrEqualTo(1),
                errorMessage: "Minimul loan term is 1 year.",
              },
              {
                function: isLessThanOrEqualTo(45),
                errorMessage: "Maximum loan term is 45 years.",
              },
            ],
            isInputValid: isLoanTermInputValid,
            setIsInputValid: setIsLoanTermInputValid,
          },
          {
            tfId: "outlined-yearly-interest-rate",
            tfLabel: "Interest Rate (%)",
            tfDescription: "What interest rate are you hoping to get?",
            tfInputType: "number",
            tfInputAdornment: "%",
            tfInputAdornmentPosition: "end",
            inputValue: yearlyInterestRate,
            setInputValue: setYearlyInterestRate,
            validationRules: [
              {
                function: required,
                errorMessage: "Please enter a value.",
              },
              {
                function: isNumericValue,
                errorMessage: "Please enter a numeric value. 🔢",
              },
              {
                function: isGreaterThanOrEqualTo(0.1),
                errorMessage: "Minimul interest rate is 0.1%.",
              },
            ],
            isInputValid: isYearlyInterestRateInputValid,
            setIsInputValid: setIsYearlyInterestRateInputValid,
          },
        ]}
        handleButtonClick={handleFormSubmit}
        resetForm={resetForm}
      />

      <Box
        sx={{
          position:
            "relative" /* Explicit positioning for the CustomLegend to be postitioned relative to his ChartContainer parent */,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {monthlyRepayment && monthlyMortgageReduction && (
          <>
            <ChartWrapper>
              <MortgageReductionChart
                data={calculateYearlyMortgageReductionOverTime(
                  monthlyMortgageReduction
                )}
              />
            </ChartWrapper>
            <CustomLegend
              typo1="Time to mortgage free:"
              typo2={monthNumberToYMString(monthlyMortgageReduction.length)}
              typo3="By paying back each month:"
              typo4={numberToCurrency(monthlyRepayment)}
            />
          </>
        )}
        {/* <Slider
            sx={{
              width: "87%",
              height: 8,
              color: customPalette.background.blue,
              margin: "0.75rem auto",
            }}
            aria-label="Accruation period slider"
            defaultValue={Number(initialState.loanTerm)}
            valueLabelDisplay="auto"
            step={Math.floor(Number(loanAmount) / 1000)}
            marks
            min={Math.ceil(Number(loanAmount) / (45 * 12))} // Max loan term = 40 years => Limit the slider minimum to avoid having to calculate too many data points
            max={Math.floor(Number(loanAmount) / (3 * 12))} // Min loan term roughly 4 years to avoid calculating silly repayment amounts
            value={Number(loanTerm)}
            onChange={handleSliderValueChange}
            valueLabelFormat={numberToCurrency(Number(loanTerm))}
          /> */}
      </Box>
    </Box>
  );
}
