const required = (input: string) =>
  input !== undefined &&
  input !== null &&
  Object.entries(typeof input === "string" ? input.trim() : input).length !== 0;

const isNumericValue = (input: any) => {
  return input - 0 == input && ("" + input).trim().length > 0;
};

const isGreaterThanOrEqualToZero = (input: any) => input >= 0;

const isGreaterThanOrEqualTo = (min: number) => (input: any) => input >= min;
const isLessThanOrEqualTo = (max: number) => (input: any) => input <= max;

const isLoanTermLessThanOrEqualTo =
  (max: number) => (loanAmount: number) => (input: any) => {
    return Math.ceil(loanAmount / input) <= max * 12;
  };

export {
  required,
  isNumericValue,
  isLessThanOrEqualTo,
  isGreaterThanOrEqualToZero,
  isGreaterThanOrEqualTo,
  isLoanTermLessThanOrEqualTo,
};
