import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";
import { homePageSections } from "../pages/home/HomePage";
import { customPalette } from "../../App";
import { Footer } from "./Footer";

interface PageWrapperProps {
  children: React.ReactNode;
}
export default function PageWrapper(props: PageWrapperProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen((prevStateValue) => !prevStateValue);
  };

  return (
    <>
      <header>
        <AppBar
          position="static"
          sx={{
            backgroundColor: customPalette.background.dark,
            color: customPalette.text.secondary,
          }}
        >
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              MyMoneyStash
            </Typography>
            <IconButton
              onClick={handleMenuClick}
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
            >
              {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="top"
          open={isMenuOpen}
          onClose={handleMenuClick}
          PaperProps={{
            sx: {
              backgroundColor: customPalette.background.dark,
              color: customPalette.text.secondary,
            },
          }}
          ModalProps={{
            sx: {
              "& .MuiBackdrop-root": {
                backgroundColor: customPalette.background.blue,
              },
            },
          }}
        >
          {/* HACK - to be able to have the drawer below the appbar (we render the app bar inside the drawer)
           */}
          <AppBar
            position="static"
            sx={{
              backgroundColor: customPalette.background.dark,
              color: customPalette.text.secondary,
            }}
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                MyMoneyStash
              </Typography>
              <IconButton
                onClick={handleMenuClick}
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
              >
                {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Toolbar>
          </AppBar>
          <List>
            {homePageSections.map((item) => (
              <ListItem disablePadding key={item.id}>
                <ListItemButton
                  component="a"
                  href={`#${item.id}`}
                  onClick={handleMenuClick}
                >
                  <ListItemText
                    primary={item.id === "home" ? "Home" : item.title} // overwrite home section title for the menu.
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </header>
      <main>{props.children}</main>
      <footer>
        <Footer sections={homePageSections}></Footer>
      </footer>
    </>
  );
}
