import { Routes, Route } from "react-router-dom";
import { PageWrapper } from "./components/base";
import { NotFound, HomePage } from "./components/pages";

export const customPalette = {
  background: {
    dark: "#1d2832",
    grey: "#ccd3dc",
    lightgrey: "#f6f8fa",
    white: "#ffffff",
    blue: "#009ceb",
    darkblue: "#0089e1",
  },
  text: {
    primary: "#0b1620",
    darkgrey: "rgb(55, 83, 108)",
    secondary: "#fff",
  },
};
function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageWrapper>
            <HomePage />
          </PageWrapper>
        }
      />
      <Route
        path="*"
        element={
          <PageWrapper>
            <NotFound />
          </PageWrapper>
        }
      ></Route>
    </Routes>
  );
}

export default App;
