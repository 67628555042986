/**
 * Format a number representing months into a string of year and months
 * 18 => "1 year, 6 months"
 * @param months
 * @returns string
 */
const monthNumberToYMString = (months: number) => {
  if (months === 1 || months === 0) {
    return `${months} month`;
  } else if (1 < months && months < 12) {
    return `${months} months`;
  } else {
    // months >= 12
    const fullYears = Math.floor(months / 12);
    if (months % 12 === 0) {
      return fullYears > 1 ? `${fullYears} years` : `${fullYears} year`;
    }

    if (fullYears > 1) {
      return months % 12 > 1
        ? `${fullYears} years, ${months % 12} months`
        : `${fullYears} years, ${months % 12} month`;
    }
    return months % 12 > 1
      ? `${fullYears} year, ${months % 12} months`
      : `${fullYears} year, ${months % 12} month`;
  }
};

export default monthNumberToYMString;
