import { Slider } from "@mui/material";
import monthNumberToYMString from "../../helpers/formatters/monthNumberToYMString";
import { customPalette } from "../../App";

interface AccruationPeriodSliderProps {
  sliderValue: number;
  handleSliderValueChange: (_: Event, value: number | number[]) => void;
}
const AccruationPeriodSlider = ({
  sliderValue,
  handleSliderValueChange,
}: AccruationPeriodSliderProps) => {
  return (
    <Slider
      sx={{
        width: "87%",
        height: 8,
        color: customPalette.background.blue,
        margin: "0.75rem auto",
      }}
      aria-label="Accruation period slider"
      defaultValue={12}
      valueLabelDisplay="auto"
      step={3}
      marks
      min={3}
      max={36}
      value={sliderValue}
      onChange={handleSliderValueChange}
      valueLabelFormat={monthNumberToYMString(sliderValue)}
    />
  );
};

export default AccruationPeriodSlider;
