import { DateTime, Duration } from "luxon";

// If contributionPeriod is O.5 (once every two month) we add contributionAmount*0.5 every month
// We default to one and the UI doesn't let the user choose the contributionPeriod
const calculateAccruedAmount = (
  contributionAmount: number,
  initialAmount = 0,
  totalAccruationPeriod = 12,
  contributionPeriod = 1 //  per month (2 => twice per month, 0.5 => once every 2 months)
) => {
  let data = [];
  const now = DateTime.now();
  for (let m = 0; m < totalAccruationPeriod; m++) {
    data.push({
      date: now.plus(Duration.fromObject({ month: m })).toFormat("LLL-yyyy"),
      accruedAmount:
        initialAmount + contributionAmount * m * contributionPeriod,
    });
  }
  return data;
};

export default calculateAccruedAmount;
