import { customPalette } from "../../../App";
import {
  SectionWrapper,
  AboutSectionContent,
  WelcomeSectionContent,
  SavingsCalculator,
  MortgageReduction,
  SignupSectionContent,
} from "./sections";
import { Box } from "@mui/material";

export type Section = {
  id: string;
  contentComponent: JSX.Element;
  title?: string;
};
// The HomePage know which section and component to render and in which order.
export const homePageSections: Section[] = [
  {
    // title: "Welcome to MyMoneyStash!",
    id: "home",
    contentComponent: <WelcomeSectionContent />,
  },
  {
    title: "Savings Calculator",
    id: "savings_calculator",
    contentComponent: <SavingsCalculator />,
  },
  {
    title: "Mortgage Calculator",
    id: "mortgage_calculator",
    contentComponent: <MortgageReduction />,
  },
  {
    // title: "About",
    id: "about",
    contentComponent: <AboutSectionContent />,
  },

  {
    id: "signup",
    contentComponent: <SignupSectionContent />,
  },
];

export default function HomePage() {
  return (
    <>
      {homePageSections.map((section, idx) => {
        const isEven = idx % 2 === 0;

        return (
          <SectionWrapper
            key={section.id}
            sectionId={section.id}
            backgroundColor={
              isEven
                ? section.id === "home" || section.id === "signup" // Custom styling for the welcome and signup sections. For other sections we alternate lightgrey and dark background colors.
                  ? customPalette.background.blue
                  : customPalette.background.lightgrey
                : customPalette.background.dark
            }
            textColor={
              isEven
                ? section.id === "home" || section.id === "signup"
                  ? customPalette.text.secondary
                  : customPalette.text.primary
                : customPalette.text.secondary
            }
            sectionTitle={section.title}
          >
            <Box id="section_component" sx={{ minHeight: "inherit" }}>
              {section.contentComponent}
            </Box>
          </SectionWrapper>
        );
      })}
    </>
  );
}
