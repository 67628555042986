import { Box, Typography } from "@mui/material";

interface SectionWrapperProps {
  sectionId: string;
  backgroundColor: string;
  textColor: string;
  sectionTitle?: string;
  children: React.ReactNode;
}

export default function SectionWrapper(props: SectionWrapperProps) {
  return (
    <Box
      component={"section"}
      id={props.sectionId}
      sx={{
        minHeight: props.sectionId === "signup" ? "inherit" : "105vh",
        backgroundColor: props.backgroundColor,
        color: props.textColor,
        paddingX: "10px",
        maxWidth: "100%", // Avoid the whole page scrolling left and right on mobile browser
      }}
    >
      {props.sectionTitle && (
        <Typography
          variant="h2"
          fontSize="1.5rem"
          sx={{ padding: "28px 20px" }}
        >
          {props.sectionTitle}
        </Typography>
      )}
      {props.children}
    </Box>
  );
}
