import {
  Bar,
  BarChart,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { customPalette } from "../../../../../App";
export interface SavingsBarChartData {
  accruedAmount: number;
  date: string;
}

interface SavingsAmountChartProps {
  data: SavingsBarChartData[];
  xAxisDataKey?: "date";
  lineDataKey?: "accruedAmount";
}

const formatCurrency = (value: number) => {
  if (value >= 10000) {
    return `${value / 1000} k£`;
  } else {
    return `${value} £`;
  }
};

const SavingsAmountChart = ({
  data,
  xAxisDataKey = "date",
  lineDataKey = "accruedAmount",
}: SavingsAmountChartProps) => {
  return (
    <>
      {/* HACK - investigate but currently it looks like the ResponsiveContainer or BarChart needs to be wrapped
      in a box (or container with explicit height and width, minHeight or minWidth make the responsive container disappear) */}
      <ResponsiveContainer width="97%">
        <BarChart data={data}>
          <XAxis
            dataKey={xAxisDataKey}
            stroke={customPalette.background.white}
            padding={{ left: 18 }}
            tickMargin={10}
            // TODO - tickFormatter={dateFormatter} -- the formatting is done directly in the data maybe its better that calculatedAccruedAmount returns a DateTime object and the consumers does the formatting?
          />
          <YAxis
            stroke={customPalette.background.white}
            // unit="£"
            mirror={true}
            tickFormatter={formatCurrency}
            tickMargin={3}
            axisLine={true}
          />
          {/* <Tooltip
            cursor={{ opacity: 0 }}
            contentStyle={{
              backgroundColor: customPalette.background.white,
              borderWidth: "3px",
              borderRadius: "5px",
              borderColor: customPalette.background.blue,
              color: customPalette.text.primary, // Needed for the date value to be colored
            }}
            itemStyle={{ color: customPalette.text.primary }}
            formatter={(value, name) => [`${value} £`]}
          /> */}

          {/* // We can keep the tooltip for desktop and mobile on click and have a
          legend on slider? => Tooltip is not very nice on desktop either as it gets over the Custom Legend
          => Legend has been replaced with CustomLegend component in SavingsCalculatorPage we like to keep things simple for the MVP!!
          */}

          <Bar dataKey={lineDataKey} fill={customPalette.background.blue} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default SavingsAmountChart;
