import { Button, Card, Box, Typography } from "@mui/material";

import FormInputGroup, { FormInputGroupProps } from "./FormInputGroup";
import { customPalette } from "../../App";
interface GenericFormProps {
  formInputGroups: FormInputGroupProps[];
  formErrorMessage?: string;
  handleButtonClick?: () => void;
  resetForm?: () => void;
}

// TODO add testing-library/react style test for GenericForm component
const GenericForm = (props: GenericFormProps) => {
  // For UX (remove red when user first loads the form)
  // because we initially set the input as valid (true)
  const invalidInputs = props.formInputGroups.filter(
    (formInputGroup: FormInputGroupProps) =>
      !formInputGroup.isInputValid || formInputGroup.inputValue.trim() === ""
  );

  return (
    <Card
      id="generic_form"
      sx={{
        borderRadius: "8px",
        backgroundColor: "common.white",
        // Container for FormInputGroup(s)
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        minWidth: "90vw",
        padding: "20px",
        marginX: "10px",
      }}
    >
      {props.formInputGroups.map((formInputGroup) => (
        <FormInputGroup
          key={formInputGroup.tfId}
          tfId={formInputGroup.tfId}
          tfLabel={formInputGroup.tfLabel}
          tfDescription={formInputGroup.tfDescription}
          tfInputAdornment={formInputGroup.tfInputAdornment}
          tfInputAdornmentPosition={formInputGroup.tfInputAdornmentPosition}
          tfInputType={formInputGroup.tfInputType}
          tfInputNumberStep={formInputGroup.tfInputNumberStep}
          tfInputNumberMax={formInputGroup.tfInputNumberMax}
          inputValue={formInputGroup.inputValue}
          setInputValue={formInputGroup.setInputValue}
          validationRules={formInputGroup.validationRules}
          isInputValid={formInputGroup.isInputValid}
          setIsInputValid={formInputGroup.setIsInputValid}
        />
      ))}

      {props.formErrorMessage && (
        <Box>
          {props.formErrorMessage.split(". ").map((sentence) => (
            <Typography variant="body1" color="error">
              {sentence}
            </Typography>
          ))}
        </Box>
      )}
      <Button
        variant="contained"
        disabled={invalidInputs.length > 0}
        onClick={props.handleButtonClick}
        size="large"
        sx={{
          marginTop: "6px",
          backgroundColor: customPalette.background.blue,
          "&:hover": {
            backgroundColor: customPalette.background.darkblue,
          },
        }}
      >
        Calculate
      </Button>

      <Button
        variant="contained"
        onClick={props.resetForm}
        size="medium"
        sx={{
          backgroundColor: customPalette.background.blue,
          "&:hover": {
            backgroundColor: customPalette.background.darkblue,
          },
        }}
      >
        Reset
      </Button>
    </Card>
  );
};

export default GenericForm;
