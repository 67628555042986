import { useState, useMemo } from "react";
import { Box } from "@mui/material";
import SavingsAmountChart from "./SavingsAmountChart";
import {
  GenericForm,
  AccruationPeriodSlider,
  CustomLegend,
  ChartWrapper,
} from "../../../../base";
import calculateAccruedAmount from "../../../../../helpers/finance/calculateAccruedAmount";
import {
  required,
  isNumericValue,
  isGreaterThanOrEqualToZero,
} from "../../../../../helpers/validation/rules";
import {
  numberToCurrency,
  monthNumberToYMString,
} from "../../../../../helpers/formatters";

function SavingsCalculator() {
  const initialState = {
    monthlySavingsContribution: "1300",
    initialSavingsAmount: "2200",
    isMonthlySavingsContributionInputValid: true,
    isInitialSavingsInputValid: true,
    accruationPeriod: 6,
    showResult: false,
  };
  // Form Input State
  const [monthlySavingsContribution, setMonthlySavingsContribution] = useState(
    initialState.monthlySavingsContribution
  );
  const [initialSavingsAmount, setInitialSavingsAmount] = useState(
    initialState.initialSavingsAmount
  );
  // Form Input Validation State
  const [
    isMonthlySavingsContributionInputValid,
    setIsMonthlySavingsContributionInputValid,
  ] = useState(initialState.isMonthlySavingsContributionInputValid);
  const [isInitialSavingsInputValid, setIsInitialSavingsInputValid] = useState(
    initialState.isInitialSavingsInputValid
  );
  // UI State
  const [accruationPeriod, setAccruationPeriod] = useState(
    initialState.accruationPeriod
  );
  const [showResult, setShowResult] = useState(initialState.showResult);

  // State-derived variable
  const accruedSavings = useMemo(
    () =>
      calculateAccruedAmount(
        Number(monthlySavingsContribution),
        Number(initialSavingsAmount),
        accruationPeriod // default to 4 but can also be set via slider
      ),
    [monthlySavingsContribution, initialSavingsAmount, accruationPeriod]
  );

  const handleButtonClick = () => {
    if (isInitialSavingsInputValid && isMonthlySavingsContributionInputValid) {
      setShowResult(true);
      return;
    }
  };

  const resetForm = () => {
    setMonthlySavingsContribution(initialState.monthlySavingsContribution);
    setInitialSavingsAmount(initialState.initialSavingsAmount);
    setIsMonthlySavingsContributionInputValid(
      initialState.isMonthlySavingsContributionInputValid
    );
    setIsInitialSavingsInputValid(initialState.isInitialSavingsInputValid);
    setAccruationPeriod(initialState.accruationPeriod);
    setShowResult(initialState.showResult);
  };
  const handleSliderChange = (_: Event, value: number | number[]) => {
    setAccruationPeriod(value as number);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "32px",
      }}
    >
      {/* Section Content wrapper each section has an h5 and ContentBox. */}

      <GenericForm
        formInputGroups={[
          {
            tfId: "outlined-initial-savings-amount",
            tfLabel: "Current Savings",
            tfDescription: "How much do you have now?",
            inputValue: initialSavingsAmount,
            setInputValue: setInitialSavingsAmount,
            validationRules: [
              {
                function: required,
                errorMessage:
                  "Please enter your current savings amount, zero or negative amount to represent no-savings or debt 🙂.",
              },
              {
                function: isNumericValue,
                errorMessage: "Please enter a numeric value. 🔢",
              },
            ],
            isInputValid: isInitialSavingsInputValid,
            setIsInputValid: setIsInitialSavingsInputValid,
            tfInputType: "number",
            tfInputNumberStep: 50,
          },
          {
            tfId: "outlined-monthly-income",
            tfLabel: "Monthly Savings",
            tfDescription: "How much do you plan to save each month?",
            inputValue: monthlySavingsContribution,
            setInputValue: setMonthlySavingsContribution,
            validationRules: [
              {
                function: required,
                errorMessage:
                  "Please enter how much you plan to save each month 🙂.",
              },
              {
                function: isNumericValue,
                errorMessage: "Please enter a numeric value. 🔢",
              },
              {
                function: isGreaterThanOrEqualToZero,
                errorMessage: "Input must be greater than or equal to £ 0.",
              },
            ],
            isInputValid: isMonthlySavingsContributionInputValid,
            setIsInputValid: setIsMonthlySavingsContributionInputValid,
            tfInputType: "number",
            tfInputNumberStep: 50,
          },
        ]}
        handleButtonClick={handleButtonClick}
        resetForm={resetForm}
      />

      {accruedSavings && (
        <Box
          sx={{
            position:
              "relative" /* Explicit positioning for the CustomLegend to be postitioned relative to his ChartContainer parent */,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ChartWrapper>
            <SavingsAmountChart data={accruedSavings} />
          </ChartWrapper>
          {showResult &&
            isInitialSavingsInputValid &&
            isMonthlySavingsContributionInputValid && (
              <>
                {/* CustomLegend is absolutely positioned */}
                <CustomLegend
                  typo1="You will have"
                  typo2={numberToCurrency(
                    accruedSavings[accruedSavings.length - 1].accruedAmount
                  )}
                  typo3="after"
                  typo4={monthNumberToYMString(accruationPeriod)}
                />
                <AccruationPeriodSlider
                  sliderValue={accruationPeriod}
                  handleSliderValueChange={handleSliderChange}
                />
              </>
            )}
        </Box>
      )}
    </Box>
  );
}

export default SavingsCalculator;
